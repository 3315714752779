import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { getVideoPoster, getVideoUrl } from '../../helpers/content';
import Icon from '../Icon';
import LinkCreator from '../LinkCreator';
import {
  H1,
  H2,
  H2H1,
  H2H3,
  Overlay,
  Video,
  VideoBox,
  BlockContainer,
  TextContent,
} from './_styles';

const VideoBlock = ({ block, index }: { block: PageBlock; index: number }) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const [play, setPlay] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      const handleTimeUpdate = () => {
        setCurrentTime(video.currentTime);
        setProgress((video.currentTime / video.duration) * 100);
      };
      video.addEventListener('timeupdate', handleTimeUpdate);
      return () => video.removeEventListener('timeupdate', handleTimeUpdate);
    }
    return () => {};
  }, []);

  const handlePlayPause = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
        setPlay(true);
      } else {
        videoRef.current.pause();
        setPlay(false);
      }
    }
  };

  const handleSeek = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (videoRef.current) {
      videoRef.current.currentTime = Number(event.target.value);
    }
  };

  const tools = _.fromPairs(block.tools.map((tool) => [tool, true]));
  let title = null;
  let description = null;
  switch (index) {
    case 0:
      title = block.title ? (
        <H1 as="h1" size={block.gridSpan}>
          {block.title}
        </H1>
      ) : (
        ''
      );
      description = block.description ? (
        <H2 as="h2" size={block.gridSpan}>
          {block.description}
        </H2>
      ) : (
        ''
      );
      break;
    default:
      title = block.title ? (
        <H2H1 as="h2" size={block.gridSpan}>
          {block.title}
        </H2H1>
      ) : (
        ''
      );
      description = block.description ? (
        <H2H3 as="h3" size={block.gridSpan}>
          {block.description}
        </H2H3>
      ) : (
        ''
      );
  }

  return (
    <BlockContainer as="div" key={block.id} span={block.gridSpan} container={block.container}>
      <VideoBox>
        <Video
          as="video"
          preload="auto"
          ref={videoRef}
          controls={false}
          autoPlay={tools.autoplay}
          loop={tools.loop}
          muted={tools.muted}
          playsInline={tools.autoplay}
          poster={getVideoPoster(_.get(block, 'file'))}
        >
          <source src={getVideoUrl(_.get(block, 'file')).webm} type="video/webm" />
          <source src={getVideoUrl(_.get(block, 'file')).mp4} type="video/mp4" />
        </Video>
        {title || description || block.link ? (
          <TextContent>
            {title && title}
            {description && description}
            {block.link && <LinkCreator href={block.link}>Подробнее</LinkCreator>}
          </TextContent>
        ) : (
          ''
        )}
        {tools.controls && (
          <Controls>
            <Button type="button" as="button" onClick={handlePlayPause}>
              {play ? (
                <Icon name="pause" colorFill="#fff" colorStroke="#fff" />
              ) : (
                <Icon name="play" colorStroke="#fff" />
              )}
            </Button>
            <ProgressControl>
              <ProgressBar progress={progress} />
              <StyledInput
                as="input"
                type="range"
                min="0"
                max={videoRef.current?.duration || 0}
                value={currentTime}
                onChange={handleSeek}
              />
            </ProgressControl>
          </Controls>
        )}
        {block.overlayColor && <Overlay color={block.overlayColor} />}
      </VideoBox>
    </BlockContainer>
  );
};

const ProgressBar = styled.div<{ progress: number }>`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: ${({ progress }) => progress}%;
  background-color: rgba(255, 255, 255, 1);
  pointer-events: none;
`;

const StyledInput = styled.input`
  appearance: none;
  width: 100%;
  height: 1px;
  background: #d9d9d9;
  opacity: 0.7;
  border-radius: 5px;
  outline: none;
  transition: background 0.3s ease;

  &::-webkit-slider-thumb {
    display: none;
  }

  &::-moz-range-thumb {
    display: none;
  }

  &:hover {
    background: #ccc;
  }
`;

const ProgressControl = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 10px;
  width: 100%;
  height: 2px;
  input[type='range'] {
    width: 100%;
  }
`;

const Controls = styled.div`
  display: flex;
  padding: 20px 0;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  position: absolute;
  z-index: 5;
  width: 80%;
  bottom: 50px;
  left: 10%;
`;

const Button = styled.button`
  margin: 0 5px;
  padding: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  &:hover,
  &:focus {
    opacity: 0.7;
  }
`;

export default VideoBlock;
