import React from 'react';
import { Link } from 'react-router-dom';

interface LinkCreatorProps {
  href: string;
  children: React.ReactNode;
}

const LinkCreator: React.FC<LinkCreatorProps> = ({ href, children }) => {
  const isExternal = href.startsWith('http://') || href.startsWith('https://');
  if (isExternal) {
    return (
      <a href={href} rel="noopener noreferrer" target="_blank">
        {children}
      </a>
    );
  }

  return <Link to={href}>{children}</Link>;
};

export default LinkCreator;
