import loadable from '@loadable/component';
import React, { useEffect } from 'react';

const Slider = loadable(() => import('react-slick'), { ssr: false });

const BaseSlider = ({ settings, children }: { settings: object; children: React.ReactNode }) => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
      // @ts-ignore - TS doesn't know about dynamic imports
      import('slick-carousel/slick/slick.css');
      // @ts-ignore - TS doesn't know about dynamic imports
      import('slick-carousel/slick/slick-theme.css');
    }
  }, []);

  return <Slider {...settings}>{children}</Slider>;
};

export default BaseSlider;
