import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const BreadCrumbs = ({ data, padding, id }: BreadCrumbsProps): ReactElement => {
  let num = 1;
  return (
    <CrumbBox as="div" id={id} padding={padding}>
      <ul itemScope itemType="https://schema.org/BreadcrumbList">
        <li itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
          <meta itemProp="position" content="1" />
          <Link to="/" itemProp="item">
            <span itemProp="name">Гланая</span>
          </Link>
        </li>
        {data.map((item) => {
          num += 1;
          return (
            <Li
              as="li"
              key={num}
              itemProp="itemListElement"
              itemScope
              itemType="https://schema.org/ListItem"
            >
              {item.url && <meta itemProp="position" content={`${num}`} />}
              {item.url ? (
                <Link to={item.url} itemProp="item">
                  <span itemProp="name">{item.name}</span>
                </Link>
              ) : (
                <span>{item.name}</span>
              )}
            </Li>
          );
        })}
      </ul>
    </CrumbBox>
  );
};

const Li = styled.li`
  line-height: 1;
`;

const CrumbBox = styled.div<{ padding: string }>`
  padding: ${({ padding }) => padding};
  ul {
    display: flex;
    align-items: center;
    list-style: none;
    flex-wrap: wrap;
    padding: 0;
    li {
      color: var(--gray);
      &:after {
        content: '/';
        margin: 0 12px 0 12px;
        color: var(--gray);
        position: relative;
        top: -2px;
        font-size: 10px;
      }
      a,
      span {
        font-size: 12px;
        font-style: normal;
        text-decoration: none;
        color: var(--gray);
        font-weight: 400;
        line-height: 120%; /* 14.4px */
        text-transform: uppercase;
      }
      a {
        &:hover {
          span {
            color: var(--red);
          }
        }
      }
    }
    li:last-child {
      &:after {
        content: '';
      }
    }
  }
  @media screen and (max-width: 1170px) {
    padding: 10px 0;
  }
`;

BreadCrumbs.defaultProps = {
  padding: '40px 0',
  id: 'breadcrumbs',
};

export default BreadCrumbs;
