import _ from 'lodash';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Icon from '../Icon';
import ImageSlider from '../ImageSlider';
import ProgressiveImage from '../ProgressiveImage';

import { SLUG_PRODUCT } from '../../helpers/constants';
import { formatPrice, getImageUrls } from '../../helpers/content';
import { isStorage, removeFromStorage, addToStorage } from '../../helpers/storage';

const ProductItem = ({
  product,
  shopBtn,
  handleCartClick,
}: {
  product: Product;
  shopBtn?: boolean;
  handleCartClick?: (id: string) => void;
}) => {
  const [favorite, setFavorite] = useState(isStorage(product._id, 'favorites'));

  const files = _.get(product, 'files', []);

  const handleFavoriteClick = () => {
    if (favorite) {
      removeFromStorage(product._id, 'favorites');
    } else {
      addToStorage(product._id, 'favorites');
    }
    setFavorite(!favorite);
  };

  return (
    <Product as="div" key={product._id}>
      <Labels>
        {_.get(product, 'is_new', false) && <Discount>NEW</Discount>}
        {_.get(product, 'discount', 0) > 0 && (
          <Discount>-{_.get(product, 'discount', 0)}%</Discount>
        )}
      </Labels>
      {files?.length > 1 ? (
        <ImageSlider to={`/${SLUG_PRODUCT}-${product.slug}`} images={files} />
      ) : (
        <LinkStyled to={`/${SLUG_PRODUCT}-${product.slug}`}>
          <ProgressiveImage
            smallImage={getImageUrls(_.get(files, 0)).thumbnail}
            largeImage={getImageUrls(_.get(files, 0)).medium}
            alt={_.get(product, 'name', '')}
            style={{ width: '100%', height: 'auto', objectFit: 'contain', maxHeight: '100%' }}
          />
        </LinkStyled>
      )}
      <ProductContent>
        <div>
          {_.get(product, 'brand.name', '') && <Brand>{_.get(product, 'brand.name', '')}</Brand>}
          <NameLink to={`/${SLUG_PRODUCT}-${_.get(product, 'slug', '')}`}>
            {_.get(product, 'name', '')}
          </NameLink>
          <Price>{formatPrice(_.get(product, 'price', ''))}</Price>
        </div>
        <Actions>
          <ButtonLink
            brand={!!_.get(product, 'brand.name', false)}
            as="button"
            type="button"
            onClick={handleFavoriteClick}
          >
            {!favorite ? (
              <Icon name="favourite" colorFill="#000" width={36} height={36} />
            ) : (
              <Icon name="favouriteFilled" colorFill="#000" width={36} height={36} />
            )}
          </ButtonLink>
        </Actions>
      </ProductContent>
      {shopBtn && (
        <Button onClick={() => handleCartClick(product._id)} as="button" type="button">
          В корзину
        </Button>
      )}
    </Product>
  );
};

const Button = styled.button`
  background: var(--dark, #000);
  color: var(--white, #fff);
  border: none;
  padding: 20px;
  cursor: pointer;
  width: 100%;
  display: flex;
  height: 50px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 12px;
  align-self: stretch;
`;

const LinkStyled = styled(Link)`
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 360px;
  min-height: 360px;
  object-fit: contain;
  text-decoration: none;
  @media (max-width: 1024px) {
    max-height: 280px;
    min-height: 280px;
  }
  @media (max-width: 768px) {
    max-height: 100%;
    min-height: 212px;
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ButtonLink = styled.button<{ brand: boolean }>`
  background: none;
  border: none;
  color: var(--dark, #000);
  cursor: pointer;
  position: relative;
  padding: 0;
  top: ${({ brand }) => (brand ? '0' : '-19px')};
`;

const Labels = styled.div`
  display: flex;
  align-items: center;
  min-height: 20px;
`;

const Discount = styled.div`
  background-color: var(--gray-label, #efefef);
  color: var(--gray, #7b7b7b);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: 1.2px;
  padding: 4px 8px;
  margin-right: 4px;
`;

const Brand = styled.div`
  color: var(--gray, #7b7b7b);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin-bottom: 4px;
`;

const Price = styled.div`
  color: var(--black, #000);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-transform: uppercase;
`;

const NameLink = styled(Link)`
  color: var(--black, #000);
  font-size: 12px;
  display: flex;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  text-transform: uppercase;
  margin-bottom: 12px;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const Product = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  max-height: 100%;
  height: 100%;
`;

const ProductContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: self-start;
  margin-top: 12px;
`;

ProductItem.defaultProps = {
  shopBtn: false,
  single: false,
  handleCartClick: () => {},
};

export default ProductItem;
