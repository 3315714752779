import { useQuery } from '@apollo/client';
import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';

import ProductBlock from 'components/blocks/ProductBlock';
import SingleImageBlock from 'components/blocks/SingleImageBlock';
import VideoBlock from 'components/blocks/VideoBlock';
import MetaTags from 'components/MetaTags';
import TextBlock from '../components/blocks/TextBlock';
import ErrorScreen from './ErrorScreen';

import { config } from '../config';
import { GQL_GET_PAGE } from '../graphql/queries';
import { Main } from '../styles/global';

const Home = () => {
  const { data, loading } = useQuery(GQL_GET_PAGE, {
    variables: { slug: 'main' },
    fetchPolicy: 'no-cache',
  });

  const page = _.get(data, 'page', {});
  const blocks = _.get(page, 'blocks', []) as PageBlock[];
  const errors = _.get(data, 'error.graphQLErrors', []);

  if (!_.isEmpty(errors)) {
    return <ErrorScreen errors={errors} />;
  }
  if (_.isEmpty(page) && !loading) {
    return <ErrorScreen />;
  }

  return (
    <Main>
      <MetaTags
        title={_.get(page, 'meta.title', '')}
        path={`${config.url.schema}://${config.url.domain}`}
        image={_.get(page, 'image', null) || '/assets/images/main.jpg'}
        created_at={_.get(page, 'created_at', 0)}
        updated_at={_.get(page, 'created_at', 0)}
        canonical={_.get(page, 'meta.canonical', '')}
        robots={_.get(page, 'meta.robots', '')}
        description={_.get(page, 'meta.description', '')}
      />
      <GridTemplate>
        {blocks.map((block, index) => {
          switch (block.type) {
            case 'video':
              return <VideoBlock key={block.id} index={index} block={block} />;
            case 'image':
              return <SingleImageBlock key={block.id} index={index} block={block} />;
            case 'product':
              return <ProductBlock key={block.id} block={block} />;
            case 'text':
              return <TextBlock key={block.id} block={block} />;
            default:
              return '';
          }
        })}
      </GridTemplate>
    </Main>
  );
};

const GridTemplate = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  position: relative;
  z-index: 0;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export default Home;
