import { useQuery } from '@apollo/client';
import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import BreadCrumbs from 'components/BreadCrumbs';
import MetaTags from 'components/MetaTags';

import { config } from '../config';
import { GQL_GET_PAGE } from '../graphql/queries';
import { H1, Main } from '../styles/global';
import ErrorScreen from './ErrorScreen';

const Page = ({ match }: { match: object }) => {
  const url = _.get(match, 'params.url');
  const { data, error, loading } = useQuery(GQL_GET_PAGE, {
    variables: { slug: url },
  });
  const page = _.get(data, 'page', {});
  const errors = _.get(error, 'graphQLErrors', []);

  if (!_.isEmpty(errors)) {
    return <ErrorScreen errors={errors} />;
  }
  if (_.isEmpty(page) && !loading) {
    return <ErrorScreen />;
  }

  const breadcrumbsData = [
    {
      name: _.get(page, 'name', ''),
      url: null,
    },
  ];
  return (
    <Main>
      <MetaTags
        title={_.get(page, 'meta.title', '')}
        path={`${config.url.schema}://${config.url.domain}/${url}`}
        image={_.get(page, 'image', null) || '/assets/images/main.jpg'}
        created_at={_.get(page, 'created_at', 0)}
        updated_at={_.get(page, 'created_at', 0)}
        canonical={_.get(page, 'meta.canonical', '')}
        robots={_.get(page, 'meta.robots', '')}
        description={_.get(page, 'meta.description', '')}
      />
      <BreadcrumbsContainer>
        <BreadCrumbs data={breadcrumbsData} />
      </BreadcrumbsContainer>
      <PageContainer>
        {_.get(page, 'name') && <H1>{_.get(page, 'name', '')}</H1>}
        {_.get(page, 'description') && (
          <Description
            as="div"
            dangerouslySetInnerHTML={{ __html: _.get(page, 'description', '') }}
          />
        )}
      </PageContainer>
    </Main>
  );
};

const BreadcrumbsContainer = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  width: 100%;
  padding: 0 30px;
  @media (max-width: 768px) {
    padding: 0 20px;
  }
  @media (max-width: 480px) {
    padding: 0 16px;
  }
`;

const PageContainer = styled.section`
  position: relative;
  width: 100%;
  min-height: 100vh;
  max-width: 974px;
  margin: 0 auto;
  padding: 0 30px;
  @media (max-width: 768px) {
    padding: 0 20px;
  }
  @media (max-width: 480px) {
    padding: 0 16px;
  }
`;

const Description = styled.div`
  margin-top: 20px;
`;

export default Page;
