import { GraphQLFormattedError } from 'graphql/error';
import React from 'react';
import _ from 'lodash';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import MetaTags from '../components/MetaTags';

export interface ErrorScreenProp {
  extensions?: {
    code: number;
    message: string;
  };
}
const ErrorScreen = (errors) => {
  const location = useLocation();
  let title;
  let message;

  const errorsState = _.get(location, 'state.errors.0', null);
  const errorState = _.get(errorsState, 'errors.0', []);
  const errorGraphql = _.get(errors, 'errors.0', []);

  const error = errorState || errorGraphql;
  switch (error?.extensions?.code) {
    case 500:
    case 501:
    case 502:
    case 503:
    case 504:
      title = 'Неизвестная ошибка';
      message = _.get(error, 'extensions.message', 'Произошла ошибка на сервере');
      break;
    case 403:
      title = 'Доступ запрещен';
      message = `Похоже у вас нет прав для просмотра этой страницы.
      Если вы считаете что это ошибка свяжитесь с нами`;
      break;
    case 400:
    case 401:
    case 402:
      title = 'Необходима авторизация';
      message = `Для просмотра этой страницы вам необходимо авторизоваться.
      Пожалуйста, войдите в свой аккаунт.`;
      break;
    default:
      title = 'Такой страницы не существует';
      message = `Мы не смогли найти страницу, которую вы ищете.
      Возможно, она была удалена, изменена или временно недоступна.`;
      break;
  }

  return (
    <Main>
      <MetaTags title={title} description={message} />
      <CenterArea>
        <H1Error>{title}</H1Error>
        <Info>{message}</Info>
        <LinkButton to="/">На главную</LinkButton>
      </CenterArea>
    </Main>
  );
};

const H1Error = styled.h1`
  text-align: center;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 28px */
  letter-spacing: 1.12px;
  text-transform: uppercase;
`;

const Main = styled.div`
  text-align: center;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CenterArea = styled.div`
  position: relative;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  max-width: 600px;
  width: 100%;
  border-radius: 10px;
  margin-bottom: 20px;
  margin-top: 60px;
`;

const Info = styled.p`
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
`;

const LinkButton = styled(Link)`
  margin-top: 40px;
  color: var(--black, #000);
  text-decoration: underline;
  transition: 0.3s;

  &:hover {
    text-decoration: none;
  }
`;

export default ErrorScreen;
