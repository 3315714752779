import React from 'react';
import styled from 'styled-components';
import { getImageUrls } from '../../helpers/content';
import LinkCreator from '../LinkCreator';
import { H1, H2, H2H1, H2H3, Overlay, TextContent } from './_styles';

const SingleImageBlock = ({ block, index }: { block: PageBlock; index: number }) => {
  let title = null;
  let description = null;
  switch (index) {
    case 0:
      title = block.title ? (
        <H1 as="h1" size={block.gridSpan}>
          {block.title}
        </H1>
      ) : (
        ''
      );
      description = block.description ? (
        <H2 as="h2" size={block.gridSpan}>
          {block.description}
        </H2>
      ) : (
        ''
      );
      break;
    default:
      title = block.title ? (
        <H2H1 as="h2" size={block.gridSpan}>
          {block.title}
        </H2H1>
      ) : (
        ''
      );
      description = block.description ? (
        <H2H3 as="h3" size={block.gridSpan}>
          {block.description}
        </H2H3>
      ) : (
        ''
      );
  }
  return (
    <ImageContainer as="div" key={block.id} span={block.gridSpan}>
      <ImageBox>
        <Image url={getImageUrls(block.file).original} />
        {title || description || block.link ? (
          <TextContent>
            {title && title}
            {description && description}
            {block.link && <LinkCreator href={block.link}>Подробнее</LinkCreator>}
          </TextContent>
        ) : (
          ''
        )}
        {block.overlayColor && <Overlay color={block.overlayColor} />}
      </ImageBox>
    </ImageContainer>
  );
};

const Image = styled.div<{ url: string }>`
  background-image: url(${({ url }) => url});
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ImageContainer = styled.div<{ span: number }>`
  grid-column: span ${({ span }) => span};
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  @media (max-width: 1170px) {
    height: 70vh;
  }
  @media (max-width: 768px) {
    grid-column: 1;
    height: 100vh;
  }
`;

const ImageBox = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  @media (max-width: 1170px) {
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 768px) {
    height: 100vh;
  }
`;

export default SingleImageBlock;
