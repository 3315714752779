import React from 'react';
import styled from 'styled-components';

import { BlockContainer } from './_styles';

const TextBlock = ({ block }: { block: PageBlock }) => {
  return (
    <BlockContainer
      background={block.overlayColor}
      container="none"
      as="div"
      key={block.id}
      span={block.gridSpan}
    >
      <TextDescription
        as="div"
        container={block.container}
        dangerouslySetInnerHTML={{
          __html: block.content,
        }}
      />
    </BlockContainer>
  );
};

const TextDescription = styled.div<{ container: string }>`
  display: block;
  padding: 10%;
  ${({ container }) => {
    switch (container) {
      case 'large':
        return 'max-width: 1380px; margin: 0 auto;';
      case 'medium':
        return 'max-width: 768px; margin: 0 auto;';
      case 'small':
        return 'max-width: 480px; margin: 0 auto;';
      default:
        return '';
    }
  }}
`;

export default TextBlock;
