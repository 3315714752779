import React, { useState, useEffect } from 'react';

type ProgressiveImageProps = {
  smallImage: string;
  largeImage: string;
  alt: string;
  className?: string;
  style?: React.CSSProperties;
};

const ProgressiveImage: React.FC<ProgressiveImageProps> = ({
  smallImage,
  largeImage,
  alt,
  className,
  style,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [currentImage, setCurrentImage] = useState(smallImage);

  useEffect(() => {
    const img = new Image();
    img.src = largeImage;
    img.onload = () => {
      setIsLoaded(true);
      setCurrentImage(largeImage);
    };
  }, [largeImage]);

  useEffect(() => {
    return () => {
      setIsLoaded(false);
      setCurrentImage('');
    };
  }, []);

  return (
    <img
      src={currentImage}
      alt={alt}
      className={className}
      style={{
        ...style,
        transition: 'opacity 0.5s ease-in-out',
        opacity: isLoaded ? 1 : 0.5,
      }}
    />
  );
};

ProgressiveImage.defaultProps = {
  className: '',
  style: {},
};

export default ProgressiveImage;
