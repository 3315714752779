import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { getImageUrls } from '../helpers/content';
import ProgressiveImage from './ProgressiveImage';

const ImageSlider = ({ to, images }: { to: string; images: FileItem[] }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleDotClick = (index: number) => {
    setCurrentIndex(index);
  };

  return (
    <SliderContainer>
      <ImagesWrapper to={to} index={currentIndex}>
        {images.map((image, index) => (
          <ImageContainer as="div" key={`${1 + index}-img`}>
            <ProgressiveImage
              smallImage={getImageUrls(image).thumbnail}
              largeImage={getImageUrls(image).medium}
              alt={`image-${index}`}
              style={{ width: '100%', height: 'auto', objectFit: 'contain', maxHeight: '100%' }}
            />
          </ImageContainer>
        ))}
      </ImagesWrapper>
      <DotsContainer>
        {images.map((__, index) => (
          <Dot
            as="button"
            type="button"
            key={`${index + 1}-dot`}
            isActive={index === currentIndex}
            onClick={() => handleDotClick(index)}
          />
        ))}
      </DotsContainer>
    </SliderContainer>
  );
};

const SliderContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
`;

const ImagesWrapper = styled(Link)<{ index: number }>`
  display: flex;
  transition: transform 0.5s ease-in-out;
  transform: ${({ index }) => `translateX(-${index * 100}%)`};
`;

const ImageContainer = styled.div`
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 360px;
  object-fit: contain;
  @media (max-width: 1024px) {
    min-height: 280px;
  }
  @media (max-width: 768px) {
    min-height: 212px;
  }
`;

const DotsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: -20px;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
`;

const Dot = styled.button<{ isActive: boolean }>`
  width: 6px;
  height: 6px;
  min-width: 6px;
  min-height: 6px;
  max-width: 6px;
  max-height: 6px;
  border-radius: 50%;
  padding: 0;
  background-color: ${({ isActive }) => (isActive ? 'black' : 'var(--gray, #ccc)')};
  margin: 0 8px;
  border: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;

export default ImageSlider;
