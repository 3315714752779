import styled, { css } from 'styled-components';

export const TextContent = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%, -60px);
  bottom: 0;
  z-index: 2;
  color: white;
  text-align: center;
  a {
    color: var(--white, #fff);
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 14.4px */
    text-transform: uppercase;
    text-decoration: underline;
    transition: all 150ms ease-in-out;
    &:hover {
      text-decoration: none;
    }
  }
  @media (max-width: 768px) {
    left: auto;
    transform: translate(0, -40px);
  }
`;

export const HStyle = css<{ size: number }>`
  color: var(--white, #fff);
  text-align: center;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 32px */
  letter-spacing: 1.28px;
  text-transform: uppercase;
  ${({ size }) => {
    switch (size) {
      case 4:
        return 'font-size: 32px';
      case 3:
        return 'font-size: 18px';
      case 2:
        return 'font-size: 24px;';
      default:
        return 'font-size: 22px;';
    }
  }}
`;

export const H2Stile = css<{ size: number }>`
  color: var(--white, #fff);
  text-align: center;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  ${({ size }) => {
    switch (size) {
      case 4:
        return 'font-size: 14px;';
      case 3:
        return 'font-size: 12px;';
      default:
        return 'font-size: 11px;';
    }
  }}
`;

export const H1 = styled.h1<{ size: number }>`
  ${HStyle}
`;

export const H2H1 = styled.h2<{ size: number }>`
  ${HStyle}
`;

export const H2 = styled.h3<{ size: number }>`
  ${H2Stile}
`;

export const H2H3 = styled.h3<{ size: number }>`
  ${H2Stile}
`;

export const Overlay = styled.div<{ color: string }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: ${({ color }) => color};
`;

export const VideoBox = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: -1;
`;

export const BlockContainer = styled.div<{
  span: number;
  container: string;
  background?: string;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  overflow: hidden;
  z-index: -1;
  grid-column: span ${({ span }) => span};
  @media (max-width: 1170px) {
    min-height: 70vh;
  }
  @media (max-width: 768px) {
    grid-column: 1;
    min-height: 100vh;
  }
  ${({ background }) => background && `background-color: ${background};`}
  ${({ container }) => {
    switch (container) {
      case 'large':
        return 'max-width: 1380px; margin: 0 auto;';
      case 'medium':
        return 'max-width: 768px; margin: 0 auto;';
      case 'small':
        return 'max-width: 480px; margin: 0 auto;';
      default:
        return '';
    }
  }}
`;

export const Video = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  object-fit: cover;
  -o-object-fit: cover;
  background: transparent;
`;
