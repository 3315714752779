/**
 * @name addToStorage
 * @description Add ID to storage
 * @param {String} id - ID
 * @param {String} name - Name of storage
 */
export const addToStorage = (id: string, name: string): void => {
  if (typeof window === 'undefined') return;
  if (!localStorage) return;
  const data = JSON.parse(localStorage.getItem(name) || '[]');
  if (!data.includes(id)) {
    data.push(id);
    localStorage.setItem(name, JSON.stringify(data));
  }
};

/**
 * @name removeFromStorage
 * @description Remove ID from storage
 * @param {String} id - ID
 * @param {String} name - Name of storage
 */
export const removeFromStorage = (id: string, name: string) => {
  if (typeof window === 'undefined') return;
  if (!localStorage) return;
  let data = JSON.parse(localStorage.getItem(name) || '[]');
  data = data.filter((_id: string) => _id !== id);
  localStorage.setItem(name, JSON.stringify(data));
};

/**
 * @name isStorage
 * @description Check if ID is in storage
 * @param {String} id - ID
 * @param {String} name - Name of storage
 * @returns {Boolean}
 */
export const isStorage = (id: string, name: string): boolean => {
  if (typeof window === 'undefined') return false;
  if (!localStorage) return false;
  const data = JSON.parse(localStorage.getItem(name) || '[]');
  return data.includes(id);
};

/**
 * @name getStorage
 * @description Get storage
 * @param {String} name - Name of storage
 * @returns {Array}
 */
export const getFormStorage = (name: string): string[] => {
  if (typeof window === 'undefined') return [];
  if (!localStorage) return [];
  return JSON.parse(localStorage.getItem(name) || '[]');
};

/**
 * @name addObjectToStorage
 * @description Add object to storage
 * @param {Object} params - Object
 * @param {String} name - Name of storage
 */
export const addObjectToStorage = (params: { _id: string; data: any }, name: string): void => {
  if (typeof window === 'undefined') return;
  if (!localStorage) return;
  const data = JSON.parse(localStorage.getItem(name) || '[]');
  const index = data.findIndex((item: { _id: string }) => item._id === params._id);
  if (index !== -1) {
    data[index] = params;
  } else {
    data.push(params);
  }
  localStorage.setItem(name, JSON.stringify(data));
};

/**
 * @name getObjectFromStorage
 * @description Get object from storage
 * @param {String} id - ID
 * @param {String} name - Name of storage
 * @returns {Object | null}
 */
export const getObjectFromStorage = (id: string, name: string): any | null => {
  if (typeof window === 'undefined') return null;
  if (!localStorage) return null;
  const data = JSON.parse(localStorage.getItem(name) || '[]');
  return data.find((item: { _id: string }) => item._id === id) || null;
};

/**
 * @name removeObjectFromStorage
 * @description Remove object from storage
 * @param {String} id - ID
 * @param {String} name - Name of storage
 */
export const removeObjectFromStorage = (id: string, name: string): void => {
  if (typeof window === 'undefined') return;
  if (!localStorage) return;
  let data = JSON.parse(localStorage.getItem(name) || '[]');
  data = data.filter((item: { _id: string }) => item._id !== id);
  localStorage.setItem(name, JSON.stringify(data));
};

/**
 * @name isObjectInStorage
 * @description Check if object is in storage
 * @param {String} id - ID
 * @param {String} name - Name of storage
 * @returns {String} - JSON string for === comparison
 */
export const isObjectInStorage = (id: string, name: string): string => {
  if (typeof window === 'undefined') return '';
  if (!localStorage) return '';
  const data = JSON.parse(localStorage.getItem(name) || '[]');
  return JSON.stringify(data.some((item: { _id: string }) => item._id === id));
};
