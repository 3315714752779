import React from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { SLUG_CATALOG } from '../../helpers/constants';

import ProductItem from '../products/ProductItem';
import BaseSlider from '../BaseSlider';
import { BlockContainer } from './_styles';

const ProductBlock = ({ block }: { block: PageBlock }) => {
  const product = _.get(block, 'products.0', {}) as Product;
  const total = _.get(block, 'products.length', 0);
  const settings = {
    dots: true,
    infinite: 4,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  if (total > 1) {
    return (
      <BlockContainer container={block.container} as="div" key={block.id} span={block.gridSpan}>
        <SliderWrap>
          {total > 1 && (
            <TopBox>
              <TopTitle>Каталог</TopTitle>
              <ProductSections>
                {block.categories?.map((c) => {
                  return (
                    <CategoryLink key={c._id} to={`/${SLUG_CATALOG}/category-${c.slug}`}>
                      {c.name}
                    </CategoryLink>
                  );
                })}
              </ProductSections>
            </TopBox>
          )}
          <BaseSlider settings={settings}>
            {block.products.map((p: Product, i) => (
              <ProductWrap>
                <ProductItem key={`${p._id}-${i + 1}`} product={p} />
              </ProductWrap>
            ))}
          </BaseSlider>
        </SliderWrap>
      </BlockContainer>
    );
  }

  return (
    <BlockContainer container={block.container} as="div" key={block.id} span={block.gridSpan}>
      <ProductWrap>
        <ProductItem product={product} />
      </ProductWrap>
    </BlockContainer>
  );
};

const CategoryLink = styled(Link)`
  color: var(--black, #000);
  margin: 0 10px;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  margin-bottom: 5px;
  &:hover {
    text-decoration: underline;
  }
  @media (max-width: 768px) {
    display: inline-block;
  }
`;

const TopBox = styled.div`
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TopTitle = styled.h2`
  color: var(--black, #000);
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 32px */
  text-transform: uppercase;
  margin-bottom: 20px;
`;

const ProductSections = styled.div`
  font-size: 16px;
  display: flex;
  align-items: center;
  margin: 0 0 20px;
  @media (max-width: 768px) {
    display: inline-block;
    text-align: center;
  }
`;

const SliderWrap = styled.div`
  width: 100%;
  padding: 80px 10px 120px;
  min-height: 700px;
`;

const ProductWrap = styled.div`
  padding: 0 10px;
  max-width: 330px;
  @media (max-width: 1024px) {
    max-width: 250px;
  }
  @media (max-width: 768px) {
    max-width: 100%;
    padding: 60px 16px;
  }
`;

export default ProductBlock;
