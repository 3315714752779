/* eslint-disable react/no-unknown-property */
import moment from 'moment';
import React from 'react';
import { Helmet } from 'react-helmet';

interface MetaInterface {
  title: string;
  description?: string;
  image?: string;
  path?: string;
  created_at?: number;
  updated_at?: number;
  canonical?: string;
  robots?: string;
}

const MetaTags = ({
  title,
  description,
  image,
  path,
  created_at,
  updated_at,
  canonical,
  robots,
}: MetaInterface) => {
  return (
    <Helmet>
      <html
        prefix="og: https://ogp.me/ns#"
        itemScope
        itemType="https://schema.org/WebPage"
        lang="ru"
      />
      <title>{title}</title>
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta charSet="utf-8" />
      <link rel="manifest" href="/manifest.json" />
      <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
      <link rel="mask-icon" href="/favicon.svg" color="#ffffff" />
      <link rel="shortcut icon" href="/favicon.ico" type="image/x-icon" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="og:type" content="website" />
      <meta charSet="UTF-8" />
      <meta name="theme-color" content="#000000" />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="application-name" content="Хохлома" />
      <meta name="apple-mobile-web-app-title" content="Хохлома" />
      <meta name="msapplication-starturl" content="/" />
      <meta name="author" content="Хохлома" />
      <meta property="og:site_name" content="Хохлома" />
      {title && [
        <meta key={0} name="title" content={title} />,
        <meta key={1} itemProp="name" content={title} />,
        <meta key={2} name="twitter:title" content={title} />,
        <meta key={3} property="og:title" content={title} />,
      ]}
      {description && [
        <meta key={4} name="description" itemProp="description" content={description} />,
        <meta key={5} name="twitter:description" content={description} />,
        <meta key={6} property="og:description" content={description} />,
      ]}
      {image && [
        <meta key={9} property="image" content={image || '/assets/images/main.jpg'} />,
        <meta key={10} property="og:image" content={image || '/assets/images/main.jpg'} />,
        <meta key={11} name="twitter:image:src" content={image || '/images/main.jpg'} />,
      ]}
      {path && [
        <meta key={7} name="url" content={path} />,
        <meta key={8} name="og:url" content={path} />,
      ]}
      {canonical && <link rel="canonical" href={canonical} />}
      {robots && <meta name="robots" content={robots} />}
      {created_at && (
        <meta
          name="published"
          itemProp="datePublished"
          content={moment.unix(created_at).format('YYYY-MM-DDTHH:mm:ss')}
        />
      )}
      {updated_at && (
        <meta
          name="updated"
          itemProp="dateModified"
          content={moment.unix(updated_at).format('YYYY-MM-DDTHH:mm:ss')}
        />
      )}
    </Helmet>
  );
};

MetaTags.defaultProps = {
  description: null,
  image: null,
  path: null,
  created_at: null,
  updated_at: null,
  canonical: null,
  robots: null,
};

export default MetaTags;
